<template>
    <header ref="header" class="header-container">
        <div class="menu-wrapper" v-click-outside="toggleSubMenuIfOpen">
            <div class="cols rw middle space">

                <nav class="col sm-12 md-12 rw nw space middle np menu-container">
                    <a :href="homeUrl" class="logo" title="Kamminga Bouw">
                        <logo/><span>Kamminga Bouw</span>
                    </a>

                    <ul class="menu-content rw nw middle stretch" v-if="content">
                        <li v-for="item in content.menu">
                            <div class="menu-item rw middle children" v-if="item.children && item.children.length > 0" :class="item.url == submenu.url ? 'active' : ''" @click="openSubmenu(item)">
                                <span v-html="item.label"/>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.0027 6.5979L4.40965 0.00488281L3.00488 1.40965L9.5979 8.00267L3.00536 14.5952L4.41013 16L11.0027 9.40744L11.0049 9.40965L12.4097 8.00488L12.4074 8.00267L12.4101 7.99999L11.0054 6.59522L11.0027 6.5979Z" fill="currentColor"></path></svg>
                            </div>

                            <a class="menu-item rw middle" v-else :href="item.url" @click="item.children && item.children.length > 0 ? console.log(item.children) : console.log('menu clciocick')">
                                <span v-html="item.label"/>
                            </a>
                        </li>
                    </ul>

                    <div class="menu-actions buttons rw middle">
                        <a href="#search" class="button icon menu tip-wrap" @click="toggleSearch">
                            <i class="bi bi-search"></i>
                            <span class="tip" v-html="__('Search')"/>
                        </a>

                        <a :href="offer" class="hide-sm-down button primary menu">
                            <span v-html="__('Request quote')"/>
                        </a>

                        <language-switch :content="content"/>

                        <a href="#menu" class="hide-md button icon menu tip-wrap" @click="openSubmenu(mainSub, true)">
                            <i v-show="!submenu" class="bi bi-list"></i>
                            <i v-show="submenu" class="bi bi-x-lg"></i>
                            <span class="tip" v-html="__('Menu')"/>
                        </a>
                    </div>
                </nav>

                <div class="submenu-fixture">
                    <div class="submenu-wrapper" ref="subWrap" v-show="submenu">
                        <div class="submenu-inner" ref="sub">
                            <div class="hide-sm button menu" v-if="submenu.type != 'main'" @click="openSubmenu(mainSub, true)">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.9973 6.5979L11.5903 0.00488281L12.9951 1.40965L6.4021 8.00267L12.9946 14.5952L11.5899 16L4.9973 9.40744L4.9951 9.40965L3.5903 8.00488L3.5926 8.00267L3.5899 7.99999L4.9946 6.59522L4.9973 6.5979Z" fill="currentColor"/></svg>
                                <span v-html="submenu.type == 'main' ? __('Close') : __('Back')"/>
                            </div>

                            <ul class="menu" :class="subType">
                                <li v-for="item in submenu.children" class="rw stretch">
                                    <a :href="item.children && item.children.length > 0 ? '#' : item.url" @click="item.children && item.children.length > 0 ? openSubmenu(item) : console.log('menu clciocick')">
                                        <figure v-if="item.img" class="cl nw oh end center icon">
                                            <img :src="item.img">
                                        </figure>

                                        <div class="inner rw space middle">
                                            <span class="w500" v-html="item.label"/>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.0027 6.5979L4.40965 0.00488281L3.00488 1.40965L9.5979 8.00267L3.00536 14.5952L4.41013 16L11.0027 9.40744L11.0049 9.40965L12.4097 8.00488L12.4074 8.00267L12.4101 7.99999L11.0054 6.59522L11.0027 6.5979Z" fill="currentColor"/></svg>
                                        </div>
                                    </a>
                                </li>

                                <li class="rw stretch" v-if="submenu.type == 'main'">
                                    <a :href="offer" class="_highlight">
                                        <div class="inner rw space middle">
                                            <span class="w500" v-html="__('Request quote')"/>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.0027 6.5979L4.40965 0.00488281L3.00488 1.40965L9.5979 8.00267L3.00536 14.5952L4.41013 16L11.0027 9.40744L11.0049 9.40965L12.4097 8.00488L12.4074 8.00267L12.4101 7.99999L11.0054 6.59522L11.0027 6.5979Z" fill="currentColor"/></svg>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <search ref="searchComponent" :screenWidth="screenWidth"/>
            </div>
        </div>

        <cookies v-if="content.cookies" :content="content.cookies"/>
    </header>
</template>

<script setup>
    import gsap  from 'gsap'
    import axios from 'axios'
    import Flag from './Flag.vue'
    import LanguageSwitch from './LanguageSwitch.vue'
</script>

<script>
    export default {
        props: ['content'],

        data() {
            return {
                lastIndex: 0,
                loading: false,
                langSelect: false,
                scrolled: false,
                scrollTop: (window.pageYOffset || document.documentElement.scrollTop),
                startPos: 200,
                menuState: false,
                submenu: false,
                subType: false,
                mainSub: false,
                screenWidth: 0
            }
        },

        created () {
            window.addEventListener('scroll', this.handleScroll);
            window.addEventListener('resize', this.handleResize);
        },

        unmounted () {
            window.removeEventListener('scroll', this.handleScroll);
            window.removeEventListener('resize', this.handleResize);
        },

        beforeMount () {
            window.addEventListener('keydown', this.handleKeydown)
        },

        beforeDestroy() {
            window.removeEventListener('keydown', this.handleKeydown)
        },

        mounted() {
            this.screenWidth = window.screenWidth

            if (this.content) {
                this.mainSub = {
                    url: '/',
                    type:"main",
                    children: this.content.menu
                }
            }
        },

        methods: {
            toggleSearch() {
                this.toggleSubMenuIfOpen()
                this.$refs.searchComponent.toggleSearch()
            },

            toggleLangIfOpen() {
                if (this.langSelect) this.langSelect = false
            },

            toggleSubMenuIfOpen() {
                if (this.submenu) this.submenu = false
            },

            openSubmenu(item=false, back=false) {
                if (item) {
                    let direction = (item.index < this.lastIndex) || back
                    this.lastIndex = item.index
                    let isOpen = this.submenu ? 0.24 : 0

                    gsap.to(this.$refs.sub, isOpen, {
                        ease: 'expo.in',
                        x: direction ? 100 : -100,
                        opacity: 0,
                        onComplete: () => {
                            this.submenu = this.submenu.url != item.url ? item : false
                            this.subType = this.submenu ? this.submenu.type : false

                            gsap.set(this.$refs.sub, {
                                x: !direction ? 100 : -100,
                                opacity: 0,
                                delay: 0.1,
                                onComplete: () => {

                                    gsap.to(this.$refs.subWrap, 0.48, {
                                        ease: 'expo.out',
                                        height: this.$refs.sub.offsetHeight
                                    })
                                    
                                    if (this.subType == 'services' || this.mobile) {
                                        gsap.set(this.$refs.sub.querySelectorAll('li'), {
                                            y: 24,
                                            opacity: 0,
                                        })

                                        gsap.to(this.$refs.sub.querySelectorAll('li'), 0.32, {
                                            ease: 'expo.out',
                                            y: 0,
                                            opacity: 1,
                                            stagger: 0.08
                                        })
                                    }

                                    gsap.to(this.$refs.sub, 0.24, {
                                        ease: 'expo.out',
                                        x: 0,
                                        opacity: 1,
                                        onComplete: () => {
                                            gsap.to(this.$refs.subWrap, 0.24, {
                                                ease: 'sine.out',
                                                height: this.$refs.sub.offsetHeight
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            },

            handleScroll (event) {
                this.scrollTop = (window.pageYOffset || document.documentElement.scrollTop)

                if (!this.scrolled && this.scrollTop > this.startPos || this.scrolled && this.scrollTop < this.startPos) {
                    this.scrolled = !this.scrolled
                }
            },

            handleResize (event) {
                this.screenWidth = window.innerWidth

                if (this.submenu) {
                    gsap.to(this.$refs.subWrap, 0.48, {
                        ease: 'expo.out',
                        height: this.$refs.sub.offsetHeight
                    })
                }
            },

            handleKeydown (e) {
                if (e.keyCode == 220) {
                    e.preventDefault()
                    this.toggleSearch()
                }
            }
        },

        computed: {
            searchResults() {
                if (this.searchQuery) return 'moieme'
                else return false
            },

            currentLanguage() {
                return this.content && this.content.languages ? this.content.languages[0] : false
            },

            homeUrl() {
                return '/' + (this.currentLanguage && this.currentLanguage.code != 'nl' ? this.currentLanguage.code : '')
            },

            offer() {
                return window ? window.offer : '#'
            },

            mobile() {
                return this.screenWidth <= 704
            }
        }
    };
</script>
