<template>
    <div class="methods-fixture cols p-y rw nw _content">

        <swiper
            ref="swiper"
            :modules="[Autoplay]"
            :speed="640"
            :auto-height="true"
            :autoplay="{
                speed: 5000,
                disableOnInteraction: true
            }"
            slides-per-view="auto">

            <swiper-slide class="col sm-12 md-4 methods-item cl space" v-for="item, index in content">
                <article>
                    <div class="label">
                        <span class="w600" v-html="index + 1"/>
                    </div>

                    <figure>
                        <img :src="item.img">
                    </figure>

                    <div class="inner-content">
                        <h3 class="w500" v-html="item.title"/>

                        <div class="content">
                            <div v-html="item.body"/>
                        </div>
                    </div>
                </article>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script setup>
    import gsap from 'gsap'
    import ScrollToPlugin from "gsap/ScrollToPlugin"
    import ScrollTrigger from "gsap/ScrollTrigger"
    import { Autoplay } from 'swiper/modules'
    import { Swiper, SwiperSlide } from 'swiper/vue'

    gsap.registerPlugin(ScrollToPlugin, ScrollTrigger)
</script>

<script>
    export default {
        props: ['content'],

        components: {
            Swiper,
            SwiperSlide,
        },

        data() {
            return {
                loaded: false
            }
        },

        mounted() {
            // let sections = gsap.utils.toArray(".methods-item")

            // const process = document.querySelector('.methods-fixture');
            // if ((typeof(process) != 'undefined' && process != null)) {
            //     let sections = gsap.utils.toArray('.methods-item');
            //     gsap.to(sections, {
            //         xPercent: -100 * Math.max(sections.length - 1, 0) + 200,
            //         ease: "none",
            //         scrollTrigger: {
            //             trigger: process,
            //             // markers: 1,
            //             scrub: 1,
            //             pin: true,
            //             snap: {
            //                 snapTo:  1 / (sections.length - 1), // snap to the closest label in the methods
            //                 duration: { min: 0.2, max: 0.64 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
            //                 delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
            //                 ease: "expo.inOut", // the ease of the snap animation ("power3" by default)
            //             },
            //             start: 'top top',
            //             end: 'bottom top',
            //         },
            //     });
            // }
        },

        methods: {
            bgColor(index) {
                return 'rgba(var(--accent),' + ((1 / this.content.length) + 1 / this.content.length * index) + ')'
            },

            opacityLevel(index) {
                let half = this.content.length,
                    asd = ((1 / half) + 1 / half * index)
                return asd > 0.5 ? asd : 0
            },

            textColor(index) {
                let half = this.content.length,
                    asd = ((1 / half) + 1 / half * index)
                return 'rgba(var(--accent),' + ((1 / this.content.length) / this.content.length * index) + ')'
            },

            __(string, vars=[]) {
                return translations.translate(string, vars) 
            }
        },
    }
</script>