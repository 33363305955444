<template>
    <div ref="wrap" class="poi-wrap">
        <div class="poi-fixture" v-for="poi in content" @click="showPoi(poi.id)" :class="[fade ? 'fade' : '', poi.id == activePoi ? 'active' : '']" :style="{ left: poi.pos.x + '%', bottom: poi.pos.y + '%' }">
            <div class="poi-icon-wrap">
                <div class="poi-icon">
                    <i class="bi bi-plus-lg"></i>
                </div>
            </div>

            <div class="poi-content cl">
                <div class="close">
                    <i class="bi bi-x-lg"></i>
                </div>

                <span class="title w600" v-if="poi.title" v-html="poi.title"/>
                <p v-if="poi.text" v-html="poi.text"/>

                <a :href="poi.url" v-if="poi.url" class="button text accent _small">
                    <span v-if="poi.label" v-html="poi.label"/>
                    <i class="bi bi-chevron-right"></i>
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
    import gsap from 'gsap'
    import { Controller } from 'swiper/modules'
    import { Swiper, SwiperSlide } from 'swiper/vue'
</script>

<script>
    export default {
        name: 'Pois',
        props: ['content', 'fade'],
        expose: ['reload'],

        data() {
            return {
                activePoi: 0
            }
        },

        mounted() {
            if (this.content.length > 0 && !this.fade && !this.mobile) this.showPoi(this.content[0].id)
            else this.reload()
        },

        methods: {
            reload() {
                if (this.fade && this.$refs.wrap.querySelectorAll('.poi-fixture').length) {
                    gsap.set(this.$refs.wrap.querySelectorAll('.poi-fixture'), {
                        scale: 0,
                        opacity: 0
                    })

                    this.$refs.wrap.querySelectorAll('.poi-fixture').forEach(item => {
                        item.classList.remove('fade')
                    })

                    gsap.to(this.$refs.wrap.querySelectorAll('.poi-fixture'), {
                        duration: 1.4,
                        ease: 'elastic.out',
                        opacity: 1,
                        scale: 1,
                        stagger: 0.32,
                        delay: 1,

                        onComplete: (e) => {
                            gsap.set(this.$refs.wrap.querySelectorAll('.poi-fixture'), {clearProps:"all"})
                            // element.style.width = null;
                            // element.style.height = null;
                        }
                    })


                    setTimeout( () => {
                        this.showPoi(this.content[0].id)
                    }, 2000)
                }
            },

            showPoi(id) {
                this.activePoi = this.activePoi == id ? 0 : id
            },

            mobile() {
                return window.innerWidth <= 704
            }
        }
    };

</script>