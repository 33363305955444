<template>
    <svg width="163" height="56" viewBox="0 0 163 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M64.446 42.1469C64.446 43.1311 64.1481 43.8876 63.5528 44.4155C62.9563 44.9438 62.1568 45.2078 61.152 45.2078H56.8487V34.1462H61.0126C61.9862 34.1462 62.7727 34.4102 63.373 34.9384C63.9741 35.4664 64.2746 36.1864 64.2746 37.0974C64.2746 37.6677 64.1218 38.1696 63.8167 38.6049C63.5105 39.0403 63.1193 39.3454 62.6436 39.5215C63.8449 39.9671 64.446 40.8416 64.446 42.1469ZM63.095 37.1133C63.095 36.4823 62.8954 36.0047 62.4958 35.6845C62.0966 35.3634 61.5714 35.2023 60.9197 35.2023H58.0295V39.0403H60.9197C61.5611 39.0403 62.0844 38.8766 62.489 38.5504C62.8916 38.2237 63.095 37.7454 63.095 37.1133ZM63.2652 42.116C63.2652 41.4943 63.066 41.0026 62.6668 40.6393C62.268 40.2771 61.7279 40.0965 61.0435 40.0965H58.0295V44.1515H61.0435C61.7279 44.1515 62.268 43.9672 62.6668 43.5992C63.066 43.232 63.2652 42.7376 63.2652 42.116Z" class="logo-accent"/>
        <path d="M74.5433 39.6762C74.5433 41.033 74.5018 41.9448 74.4187 42.411C74.2839 43.0935 73.9784 43.6748 73.5019 44.1505C72.7352 44.9174 71.7932 45.2993 70.6753 45.2993C69.5562 45.2993 68.6135 44.9174 67.8476 44.1505C67.3711 43.6748 67.0648 43.0935 66.93 42.411C66.8477 41.9448 66.8066 41.033 66.8066 39.6762C66.8066 38.3195 66.8477 37.4074 66.93 36.9421C67.0648 36.2586 67.3711 35.6782 67.8476 35.2015C68.6135 34.4357 69.5562 34.0527 70.6753 34.0527C71.7932 34.0527 72.7352 34.4357 73.5019 35.2015C73.9784 35.6782 74.2839 36.2586 74.4187 36.9421C74.5018 37.4074 74.5433 38.3195 74.5433 39.6762ZM73.3625 39.6762C73.3625 38.4647 73.3214 37.6359 73.238 37.1912C73.134 36.6724 72.9169 36.2427 72.5855 35.9009C72.0675 35.3729 71.431 35.1088 70.6753 35.1088C69.9189 35.1088 69.2812 35.3729 68.7632 35.9009C68.4318 36.2427 68.2136 36.6724 68.1108 37.1912C68.0285 37.6359 67.9873 38.4647 67.9873 39.6762C67.9873 40.8875 68.0285 41.7162 68.1108 42.162C68.2136 42.6796 68.4318 43.1096 68.7632 43.4512C69.2812 43.9791 69.9189 44.2435 70.6753 44.2435C71.431 44.2435 72.0675 43.9791 72.5855 43.4512C72.9169 43.1096 73.134 42.6796 73.238 42.162C73.3214 41.7162 73.3625 40.8875 73.3625 39.6762Z" class="logo-accent"/>
        <path d="M84.893 41.556C84.893 42.6533 84.5277 43.5522 83.7976 44.2516C83.067 44.9508 82.1429 45.3 81.0239 45.3C79.9052 45.3 78.9838 44.9508 78.259 44.2516C77.5334 43.5522 77.1723 42.6533 77.1723 41.556V34.1453H78.3519V41.4635C78.3519 42.3014 78.5953 42.9756 79.0821 43.4822C79.57 43.9903 80.216 44.2431 81.0239 44.2431C81.8321 44.2431 82.482 43.9903 82.9744 43.4822C83.4658 42.9756 83.7111 42.3014 83.7111 41.4635V34.1453H84.893V41.556Z" class="logo-accent"/>
        <path d="M99.3562 34.1461L96.5285 45.2073H95.4258L92.9705 36.352L90.5165 45.2073H89.4138L86.5872 34.1461H87.8445L90.0034 43.0633L92.4426 34.1461H93.4996L95.9388 43.0633L98.0977 34.1461H99.3562Z" class="logo-accent"/>
        <path d="M64.8793 11.7541L59.7109 18.1377V11.7541H56.8487V26.4146H59.7109V22.0084L61.606 19.7434L65.3728 26.4146H68.7087L63.5002 17.5806L68.3587 11.7541H64.8793Z" class="logo-dark"/>
        <path d="M81.8658 26.4148H78.88L78.0158 23.8202H72.8062L71.9196 26.4148H68.9346L74.2677 11.7542H76.512L81.8658 26.4148ZM77.2536 21.4112L75.4616 16.0781L73.6086 21.4112H77.2536Z" class="logo-dark"/>
        <path d="M96.9626 26.4148H94.1001V17.8691L91.2997 23.4289H89.3644L86.5433 17.8691V26.4148H83.6811V11.7542H86.4909L90.3054 19.6809L94.1001 11.7542H96.9626V26.4148Z" class="logo-dark"/>
        <path d="M113.658 26.4148H110.796V17.8691L107.996 23.4289H106.06L103.239 17.8691V26.4148H100.377V11.7542H103.187L107.002 19.6809L110.796 11.7542H113.658V26.4148Z" class="logo-dark"/>
        <path d="M119.937 26.4146H117.075V11.7541H119.937V26.4146Z" class="logo-dark"/>
        <path d="M134.578 26.4148H132.025L126.219 17.417V26.4148H123.355V11.7542H125.91L131.716 20.7315V11.7542H134.578V26.4148Z" class="logo-dark"/>
        <path d="M148.439 20.3869C148.439 22.3961 147.972 23.8896 147.039 24.8661C145.967 25.9813 144.588 26.5383 142.9 26.5383C141.334 26.5383 140.016 26.0028 138.946 24.9326C138.288 24.2735 137.862 23.429 137.67 22.3989C137.559 21.795 137.504 20.6903 137.504 19.0844C137.504 17.4778 137.559 16.3728 137.67 15.7691C137.862 14.7392 138.289 13.8947 138.949 13.2366C140.021 12.1651 141.342 11.6296 142.909 11.6296C144.505 11.6296 145.805 12.1034 146.809 13.0511C147.676 13.8879 148.218 14.9795 148.439 16.3252H145.549C145.204 14.8973 144.324 14.1838 142.906 14.1838C142.149 14.1838 141.537 14.4444 141.069 14.9658C140.711 15.3776 140.491 16.0369 140.408 16.9434C140.38 17.1895 140.367 17.9036 140.367 19.0844C140.367 20.2652 140.38 20.9786 140.408 21.2258C140.491 22.1461 140.71 22.8109 141.067 23.2229C141.521 23.7313 142.13 23.985 142.9 23.985C143.764 23.985 144.444 23.7108 144.938 23.1612C145.377 22.6807 145.597 22.0281 145.597 21.2052V20.649H142.9V18.2399H148.439V20.3869Z" class="logo-dark"/>
        <path d="M162.663 26.4148H159.676L158.812 23.8202H153.603L152.718 26.4148H149.732L155.064 11.7542H157.31L162.663 26.4148ZM158.05 21.4112L156.259 16.0781L154.406 21.4112H158.05Z" class="logo-dark"/>
        <path opacity="0.64" d="M23.6828 56C15.2917 56 2.78928 41.0268 1.58414 38.9714C0.102845 36.4444 0.00266625 32.9585 0 32.8115V10.849C0 0.939281 21.2211 0.0685639 23.6416 0.000765025L23.6828 2.20445e-06L23.7247 0.000765025C26.146 0.0685639 47.3663 0.939281 47.3663 10.849V32.7751C47.3625 32.9585 47.2635 36.4444 45.7803 38.9714C44.577 41.0268 32.0757 56 23.6828 56ZM3.0559 32.7751C3.0559 32.7751 3.1458 35.5923 4.22068 37.4266C5.44182 39.5081 17.2426 52.9429 23.6828 52.9429C30.1233 52.9429 41.9245 39.5081 43.1445 37.4266C44.0461 35.8892 44.2906 33.5212 44.3112 32.7385V10.849C44.3112 4.97636 29.0823 3.21512 23.6828 3.05705C18.2829 3.21512 3.0559 4.97636 3.0559 10.849V32.7751Z" class="logo-dark"/>
        <path d="M41.25 31.8227L31.3636 21.3633L23.5214 34.0033L23.5355 49.6739C23.5831 49.6759 23.6356 49.6843 23.6825 49.6843C27.923 49.6843 38.4908 38.8917 40.5218 35.6541C40.9853 34.862 41.2287 33.2151 41.2531 32.5053V31.8199L41.25 31.8227Z" class="logo-accent"/>
        <path d="M23.6486 17.5489L27.3917 16.5106L23.6513 15.5873L19.9662 16.5799L14.7011 15.1763L6.11346 29.0156V10.6456C6.11346 10.3561 7.40012 8.60058 13.5066 7.21489C18.1177 6.16896 22.8659 5.94347 23.6879 5.91071C24.5117 5.94271 29.1594 6.16058 33.7613 7.19165C39.9539 8.58001 41.2554 10.3534 41.2554 10.6456V29.363L32.2953 19.8662L27.416 18.4759" class="logo-dark"/>
        <path d="M27.4567 22.2542L27.3874 16.5107L23.6432 17.5182L23.7491 28.1855L27.4567 22.2542Z" class="logo-accent"/>
    </svg>
</template>

<script>
    export default {
        name: 'Logo',

        data() {
            return {
            }
        },
    }
</script>