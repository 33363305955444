<template>
    <figure class="flag">
        <svg v-if="code === 'gr'" width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="28" height="20" fill="white"/>
            <path d="M11 0H0V7H11V0Z" fill="#DA121A"/>
            <path d="M28 13H17V20H28V13Z" fill="#DA121A"/>
            <path d="M28 0H17V7H28V0Z" fill="#0F47AF"/>
            <path d="M11 13H0V20H11V13Z" fill="#0F47AF"/>
            <path d="M15 0H13V9H0V11H13V20H15V11H28V9H15V0Z" fill="#078930"/>
        </svg>

        <svg v-else width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="7" width="28" height="6" fill="white"/>
            <rect width="28" height="7" fill="#DA121A"/>
            <rect y="13" width="28" height="7" fill="#0F47AF"/>
        </svg>
    </figure>
</template>

<script>
    export default {
        name: 'Flag',
        props: ['code']
    }
</script>