<template>
    <div class="language-switch" v-click-outside="toggleLangIfOpen" v-if="currentLanguage && !mobile">
        <div class="language-current rw nw middle" @click="langSelect = !langSelect">
            <flag :code="currentLanguage.code"/>
            <span class="label">{{ currentLanguage.label }}</span>
        </div>

        <ul v-show="langSelect" class="light" :class="[langSelect ? 'active': '']">
            <li v-for="lang in content.languages">
                <a :href="lang.url" rel="alternate">
                    <flag :code="lang.code"/>
                    <span class="tip" v-html="lang.label"/>
                </a>
            </li>
        </ul>
    </div>
</template>

<script setup>
    import gsap  from 'gsap'
    import axios from 'axios'
    import Flag from './Flag.vue'
</script>

<script>
    export default {
        props: ['content'],

        data() {
            return {
                langSelect: false
            }
        },

        methods: {
            toggleLangIfOpen() {
                if (this.langSelect) this.langSelect = false
            }
        },

        computed: {
            currentLanguage() {
                return this.content && this.content.languages ? this.content.languages[0] : false
            },

            mobile() {
                return window.innerWidth <= 704
            }
        }
    };
</script>
