<template>
    <div class="gallery-wrapper poi-frame">
        <div class="cols cl center middle">
            <swiper 
                :speed="560"
                :autoplay="{delay: 4000}"
                :pagination="false"
                :initialSlide="0" 
                :centered-slides="false"
                :auto-height="true"
                slides-per-view="auto">

                <swiper-slide v-for="image in content">
                    <pois v-if="image.pois.length > 0" :content="image.pois"/>
                    <img :src="image.src" :alt="image.label ? image.label : ''"/>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>

<script setup>
    import gsap from 'gsap'
    import { Controller } from 'swiper/modules'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import Pois from './Pois'
</script>

<script>
    export default {
        name: 'Gallery',
        props: ['content'],
        components: ['pois'],

        components: {
            Swiper,
            SwiperSlide,
        },

        data() {
            return {
            }
        },

        mounted() {
        },

        methods: {
        }
    };

</script>