<template>
    <div class="cols activity-list">
        <div class="col np sm-12 md-12 rw">
            <div class="list-heading-wrap">
                <div class="list-heading" :class="state ? 'active' : ''" @click="toggle">
                    <div class="rw nw middle inner">
                        <i class="toggle">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.0027 6.5979L4.40965 0.00488281L3.00488 1.40965L9.5979 8.00267L3.00536 14.5952L4.41013 16L11.0027 9.40744L11.0049 9.40965L12.4097 8.00488L12.4074 8.00267L12.4101 7.99999L11.0054 6.59522L11.0027 6.5979Z" fill="currentColor"/></svg>
                        </i>
                        <h3 v-html="content['type'] ? (query.length > 0 ? __('%% activities', [content['type']]) : __('All %% activities', [content['type'].toLowerCase()])) : __('All activities')"/>
                        <div class="count w600 cl center middle">
                            <span v-html="query.length > 0 ? sorted.length : content['items'].length"/>
                        </div>
                    </div>
                </div>

                <div class="input-wrap rw nw middle" v-show="state">
                    <input type="text" name="search" :placeholder="__('Search activities..')" v-model="query"/>
                    <i class="bi bi-search"/>
                </div>
            </div>

            <ul v-show="state" v-if="sorted.length > 0">
                <li v-for="item in sorted">
                    <a :href="item['url']" class="rw stretch">
                        <div class="inner rw space middle">
                            <span class="w500" v-html="item['name']"/>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.0027 6.5979L4.40965 0.00488281L3.00488 1.40965L9.5979 8.00267L3.00536 14.5952L4.41013 16L11.0027 9.40744L11.0049 9.40965L12.4097 8.00488L12.4074 8.00267L12.4101 7.99999L11.0054 6.59522L11.0027 6.5979Z" fill="currentColor"/></svg>
                        </div>
                    </a>
                </li>
            </ul>

            <div v-show="state" v-else class="cols rw center">
                <div class="col sm-12 md-8 rw center">
                    <div class="no-results" v-if="query.length">
                        <h3 v-html="__('No results found for %%', [query])"/>
                        <p v-html="__('Please check your search input')"/>
                    </div>

                    <div class="no-results" v-else>
                        <h3 v-html="__('No activities found')"/>
                        <p v-html="__('Please add some in the page editor')"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import gsap from 'gsap'
    import _ from 'lodash'
</script>

<script>
    export default {
        props: ['content'],

        data() {
            return {
                loaded: false,
                state: true,
                query: ''
            }
        },

        created () {
        },

        unmounted () {
        },

        mounted() {
            this.state = !this.mobile
        },

        methods: {
            toggle () {
                this.state = !this.state
            }
        },

        computed: {
            sorted() {
                let search = this.query.toLowerCase()
                return this.query ? _.filter(this.content['items'],function(item){
                    let name = item['name'].toLowerCase()
                    let keywords = item['keywords'].toLowerCase()
                    return name.indexOf(search)>-1 || keywords.indexOf(search)>-1
                }) : this.content['items']
            },

            mobile() {
                return window.innerWidth <= 704
            }
        }
    };
</script>
