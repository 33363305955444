'use strict'

// Imports
import gsap 				from 'gsap'

import { createApp } 		from 'vue'
import PerfectScrollbar  	from 'vue3-perfect-scrollbar'

// Local modules
import * as translations 	from './modules/translations'
import ClickOutside 	 	from './modules/click-outside.js';

// Vue components 
import Activities  			from './components/Activities.vue'
import Logo 	   			from './components/Logo.vue'
import Cookies 	   			from './components/Cookies.vue'
import Gallery 	   			from './components/Gallery.vue'
import Catalog 	   			from './components/Catalog.vue'
import ComplexGallery 		from './components/ComplexGallery.vue'
import Methods 	   			from './components/Methods.vue'
import Stacked 	   			from './components/Stacked.vue'
import Verbouw 	   			from './components/Verbouw.vue'
import Services 	   		from './components/Services.vue'
import ContinuousSlider 	from './components/ContinuousSlider.vue'
import ProjectsSlider 	   	from './components/ProjectsSlider.vue'
import Search 	   			from './components/Search.vue'
import HeaderBlock 			from './components/HeaderBlock.vue'

let components = document.querySelectorAll('.component')

if (components) {
	components.forEach(component => {
		let app = createApp()

		app.directive('click-outside', ClickOutside);

		// Register components 
		app.component('Logo', Logo)
		app.component('Search', Search)
		app.component('Gallery', Gallery)
		app.component('Cookies', Cookies)
		app.component('ComplexGallery', ComplexGallery)
		app.component('Methods', Methods)
		app.component('Catalog', Catalog)
		app.component('Stacked', Stacked)
		app.component('Verbouw', Verbouw)
		app.component('Services', Services)
		app.component('Activities', Activities)
		app.component('HeaderBlock', HeaderBlock)
		app.component('ProjectsSlider', ProjectsSlider)
		app.component('ContinuousSlider', ContinuousSlider)

		app.config.globalProperties.__ = translations.translate
		app.config.globalProperties.__link = window.urls

		app.use(PerfectScrollbar, {
			watchOptions: true,
			options: {
				suppressScrollX: true
			}
		})

		// Start Vue app
		app.mount(component)
	})
}