<template>
    <div class="c-slider oh">
        <swiper 
            :modules="[Controller, Autoplay]"
            :speed="2000"
            :loop="true"
            :autoplay="autoplay"
            :pagination="false"
            :initial-slide="start"
            :slide-to-clicked-slide="true"
            :space-between="80">

            <swiper-slide v-for="image in content">
                <img :src="image.src" :alt="image.name ? image.name : ''"/>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script setup>
    import gsap from 'gsap'
    import { Controller, Autoplay } from 'swiper/modules'
    import { Swiper, SwiperSlide } from 'swiper/vue'
</script>

<script>
    export default {
        name: 'ContinuousSlider',
        props: ['content'],

        components: {
            Swiper,
            SwiperSlide,
        },

        data() {
            return {
                autoplay: {
                    delay: 0, 
                    disableOnInteraction: false
                }
            }
        },

        computed: {
            start() {
                return Math.round(this.content.length / 2) + 1
            }
        }
    };

</script>