<template>
    <div class="complex-gallery-wrapper poi-frame">
        <div class="cols cl center middle">
            <swiper
                ref="swiper"
                :modules="[Autoplay]"
                :speed="640"
                :autoplay="auto ? {
                    speed: 5000,
                    disableOnInteraction: false
                } : false"
                :auto-height="true"
                :loop="loop"
                :pagination="true"
                :initialSlide="Math.round((content.length - 1) / 2)" 
                :centered-slides="true"
                @autoplayTimeLeft="onAutoplayTimeLeft"
                slides-per-view="auto">

                <swiper-slide v-for="image in content">
                    <pois v-if="image.pois.length > 0" :content="image.pois"/>
                    <img :src="image.src" :alt="image.label ? image.label : ''"/>
                    <circular-progress v-show="auto" :size="48" :paused="paused" :progress="progress"/>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>

<script setup>
    import gsap from 'gsap'
    import { Autoplay } from 'swiper/modules'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import Pois from './Pois'
    import CircularProgress from './CircularProgress'
</script>

<script>
    export default {
        name: 'ComplexGallery',
        props: ['content', 'auto', 'loop'],
        components: ['pois'],

        components: {
            Swiper,
            SwiperSlide,
        },

        data() {
            return {
                paused: false,
                progress: 0
            }
        },

        methods: {
            onAutoplayTimeLeft (s, time, progress) {
                this.progress = progress
            },

            resetAutoPlay() {
                // this.$refs.swiper.autoplay.play
                console.log('TODO autoplay reset')
            }
        }
    };

</script>