<template>
    <div class="progress-fixture" :class="paused ? 'paused' : ''">
        <svg :style="svgStyle" fill="rgba(0,0,0,0)">
            <circle :cx="r" :cy="r" :r="cr" stroke-linecap="round" stroke-width="4" :stroke-dasharray="circumference*progress+','+circumference"/>
        </svg>

        <i class="bi bi-play"></i>
    </div>
</template>

<script>
    export default {
        name: 'CircularProgress',
        props: ['progress', 'paused', 'size'],

        data() {
            return {
                linecolor: 'white',
                width: 16,
                text: '',
                round: ''
            }
        },

        methods: {
            
        },

        computed: {
            circumference() {
                return Math.PI*(this.size-this.width)
            },
            r() {
                return this.size/2
            },
            cr() {
                return this.r-this.width/2
            },
            svgStyle() {
                return 'width:'+this.size+'px;height:'+this.size+'px;stroke:'+this.linecolor+';'
            },
            backStyle() {
                return 'color:'+this.linebgcolor+';border-width:'+this.width+'px' 
            }
        }
    };

</script>