'use strict'

export function init () {
    if (!get('lang')) set('lang', 'en-US'); // Default language is english
}

export function set (name, value, expirationDays) {
    let date = new Date()
    date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000))
    const expires   = "expires=" + date.toUTCString()
    const samesite  = "SameSite=Lax"
    document.cookie = name + "=" + value + "; " + expires + "; " + samesite + "; path=/"
}

export function get (name) {
    const setName = name + "="
    const cDecoded = decodeURIComponent(document.cookie)
    const cArr = cDecoded .split('; ')
    let res
    cArr.forEach(val => {
        if (val.indexOf(setName) === 0) res = val.substring(setName.length)
    })
    return res
}