<template>
    <div class="cookie-fixture _dark" ref="notice" v-click-outside="hideIfNotHidden">
        <div class="cookie-wrapper" v-if="!hidden">
            <div class="cookie-container s_flappie">
                <div class="button close cl center middle" @click="hideIfNotHidden">
                    <i class="bi bi-x-lg"/>
                </div>

                <div class="cl">
                    <div class="intro">
                        <h1 v-html="cc.label"/>
                        <div class="content" v-html="cc.heading"/>
                    </div>

                    <ul class="cookie-options">
                        <li v-for="option in cc.options" :id="option.level" @click="option.active = !option.active" :class="option.always_active ? 'disabled' : ''">
                            <div class="input-checkbox" :class="(option.always_active || option.active) ? 'checked' : ''"></div>
                            <span class="title">{{option.label}}<small v-if="option.always_active" v-html="__('Always active')"/></span>
                            <p v-html="option.description"/>
                        </li>
                    </ul>
                </div>

                <div class="buttons rw">
                    <a :href="cc.url" v-if="cc && cc.url" class="button text">
                        <span v-html="__('Cookie policy')"/>
                    </a>

                    <div class="button primary" @click="hideIfNotHidden">
                        <span v-html="__('Accept cookies')"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import gsap  from 'gsap'
    import * as cookies from './../modules/cookie.js';
</script>

<script>
    export default {
        props: ['content'],

        data() {
            return {
                cc: {},
                hidden: true,
                events: true,
                yOffset: 480,
                currentLevel: 'level_0'
            }
        },

        mounted() {
            if (this.content) {
                this.cc = this.content

                this.hidden = true
                this.events = false

                this.cc.options.forEach( option => {
                    let cookieVal = cookies.get('consent_' + option.level)
                    if (!cookieVal) {
                        this.hidden = false 
                        this.events = true 
                    }
                    option.active = Boolean(cookieVal && cookieVal == 'true' || option.always_active)
                })

                let cookieSwitch = document.querySelector('#toggle-cookies')

                cookieSwitch.addEventListener('click', e => {
                    e.preventDefault()
                    this.showIfNotShown()
                })
            }
        },

        methods: {
            toggleSearch() {
                this.toggleSubMenuIfOpen()
                this.$refs.searchComponent.toggleSearch()
            },

            hideIfNotHidden() {
                if (!this.hidden && this.events) {
                    gsap.to(this.$refs.notice, 0.32, {
                        ease: 'back.in(1.7)',
                        y: this.yOffset,
                        opacity: 0,
                        onComplete: () => {

                            this.cc.options.forEach( option => {
                                cookies.set('consent_' + option.level, String(option.active), 0.01)
                            })

                            this.hidden = true
                            this.events = false
                        }
                    })
                }
            },

            showIfNotShown() {
                if (this.hidden) {
                    this.hidden = false

                    gsap.set(this.$refs.notice, {
                        y: this.yOffset,
                        opacity: 0
                    })

                    gsap.to(this.$refs.notice, 0.32, {
                        ease: 'back.out(1.7)',
                        y: 0,
                        opacity: 1,

                        onComplete: () => {
                            this.events = true
                        }
                    })
                }
            }
        }
    }
</script>
