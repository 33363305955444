<template>
    <div class="services-image-wrap">
        <figure class="services-image" ref="images">
            <img class="base op-0" :src="images[0]" alt="Service image">
            <img class="anim" v-for="image in images" :src="image" alt="Service image">
            <pois ref="poisCon" v-show="pois.length > 0" :content="pois" :fade="true"/>
            <img class="bus" ref="bus" src="http://localhost:8000/media/images/bus1.original.avif">
        </figure>
    </div>
</template>

<script setup>
    import gsap from 'gsap'
    import Pois from './Pois'
</script>

<script>
    export default {
        name: 'Stacked',
        expose: ['set'],
        components: [Pois],
        props: ['data'],

        data() {
            return {
                speed: 0.64,
                images: [],
                pois: []
            }
        },

        mounted() {
            if (this.data) {
                this.images = this.data['images']
                this.set(this.data)
            }

            let timeline = gsap.timeline()

            gsap.set(this.$refs.bus, {
                opacity: 0,
                x: '-100rem',
                y: '50rem',
            })

            timeline.to(this.$refs.bus, {
                duration: 1.5
            })

            timeline.to(this.$refs.bus, {
                ease: 'back.out(0.5)',
                opacity: 1,
                x: '0',
                y: '0',
                duration: 1
            })

            timeline.to(this.$refs.bus, {
                opacity: 1,
                x: '0',
                y: '0',
                duration: 4
            })

            timeline.to(this.$refs.bus, {
                ease: 'sine.in',
                opacity: 1,
                x: '100rem',
                y: '-50rem',
                duration: 2
            })
        },

        methods: {
            set(content) {
                if (this.$refs.images.querySelectorAll('.anim')) {
                    this.pois = []
                    gsap.to(this.$refs.images.querySelectorAll('.anim'), this.speed, {
                        ease: 'expo.in',
                        y: '100%',
                        opacity: 0,
                        stagger: this.speed / 3,
                        onComplete: () => {
                            this.images = content['images']
                            this.pois = content['pois']
                            this.reload()
                        }
                    })
                } 
            },

            reload() {
                gsap.set(this.$refs.images.querySelectorAll('.anim'), {
                    y: '-100%',
                    opacity: 0
                })

                gsap.to(this.$refs.images.querySelectorAll('.anim'), this.speed, {
                    ease: 'expo',
                    y: '0%',
                    opacity: 1,
                    stagger: this.speed / 2.5
                })

                this.$refs.poisCon.reload()
            }
        }
    }

</script>