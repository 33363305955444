<template>
    <div class="cols projects-slider">
        <div class="col sm-12 md-12 np">
            <swiper 
                :modules="[Controller, Autoplay]"
                :speed="400"
                :loop="true"
                :centered-slides="true"
                :slide-to-clicked-slide="false"
                :loop-additional-slides="content.length"
                slides-per-view="auto">

                <swiper-slide v-for="project in content">
                    <a :href="project.url" class="cl oh project-slide _flappie" :aria-label="project.heading">
                        <div class="cl inner space stretch">
                            <div class="cl grow">
                                <h2 class="">{{ project.name }}</h2>

                                <address class="rw base">
                                    <span class="street">{{ project.street }}</span>
                                    <span class="city">{{ project.city }}</span>
                                </address>
                            </div>

                            <ul class="rw nw tags fadeout">
                                <li v-for="tag in project.tags">{{ tag }}</li>
                            </ul>

                            <svg class="icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 40C14.6957 40 9.60859 37.8929 5.85786 34.1421C2.10714 30.3914 -6.42368e-07 25.3043 -8.74228e-07 20C-1.10609e-06 14.6957 2.10714 9.60859 5.85786 5.85786C9.60859 2.10714 14.6957 -6.42368e-07 20 -8.74228e-07C25.3043 -1.10609e-06 30.3914 2.10713 34.1421 5.85786C37.8929 9.60859 40 14.6957 40 20C40 25.3043 37.8929 30.3914 34.1421 34.1421C30.3914 37.8929 25.3043 40 20 40ZM12.9925 25.24C12.8731 25.3553 12.7779 25.4932 12.7124 25.6457C12.6469 25.7982 12.6124 25.9623 12.6109 26.1282C12.6095 26.2942 12.6411 26.4588 12.704 26.6124C12.7668 26.7661 12.8596 26.9056 12.977 27.023C13.0944 27.1404 13.2339 27.2332 13.3876 27.296C13.5412 27.3589 13.7058 27.3905 13.8718 27.3891C14.0377 27.3876 14.2017 27.3531 14.3543 27.2876C14.5068 27.2221 14.6447 27.1269 14.76 27.0075L25 16.7675L25 23.6875C25 24.019 25.1317 24.337 25.3661 24.5714C25.6005 24.8058 25.9185 24.9375 26.25 24.9375C26.5815 24.9375 26.8995 24.8058 27.1339 24.5714C27.3683 24.337 27.5 24.019 27.5 23.6875L27.5 13.75C27.5 13.4185 27.3683 13.1005 27.1339 12.8661C26.8995 12.6317 26.5815 12.5 26.25 12.5L16.3125 12.5C15.981 12.5 15.663 12.6317 15.4286 12.8661C15.1942 13.1005 15.0625 13.4185 15.0625 13.75C15.0625 14.0815 15.1942 14.3995 15.4286 14.6339C15.663 14.8683 15.981 15 16.3125 15L23.2325 15L12.9925 25.24Z" fill="white"/>
                            </svg>
                        </div>

                        <figure v-if="project.image">
                            <img :src="project.image" :alt="project.name">
                        </figure>
                    </a>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>

<script setup>
    import gsap from 'gsap'
    import { Controller, Autoplay } from 'swiper/modules'
    import { Swiper, SwiperSlide } from 'swiper/vue'
</script>

<script>
    export default {
        name: 'ProjectsSlider',
        props: ['content'],

        components: {
            Swiper,
            SwiperSlide,
        },

        data() {
            return {
                autoplay: {
                    delay: 3000, 
                    disableOnInteraction: true
                }
            }
        },

        computed: {
            duplicatedContent() {
                return this.content
            }
        }
    }

</script>