'use strict'

let translations = {
    "nl": {
        "Cookie policy": "Cookie beleid",
        "Always active": "Altijd actief",
        "Accept cookies": "Cookies accepteren",
        "No activities found": "Geen werkzaamheden gevonden",
        "Please add some in the page editor": "Voeg werkzaamheden toe met de pagina editor",
        "No results found for %%": 'Geen resultaten voor <i>"%%"</i>',
        "Please check your search input": "We hebben geen resultaten gevonden op basis van de zoekopdracht.",
        "Search activities..": "Werkzaamheden zoeken..",
        "Active filters": "Actieve filters",
        "Show vacancies": "Toon vacatures",
        "Show projects": "Toon projecten",
        "Filter vacancies": "Filter vacatures",
        "Filter projects": "Filter projecten",
        "Close": "Sluiten",
        "Back": "Terug",
        "Menu": "Menu",
        "Quote": "Offerte",
        "Your own van": "Je eigen bus",
        "Education": "Opleiding",
        "Hours": "Werktijden",
        "Location": "Omgeving",
        "Startdate": "Vanaf",
        "job_hours": "Aantal uur",
        "location": "Omgeving",
        "education_level": "Opleiding",
        "No results found": "Geen resultaten",
        "Please check your filters": "We hebben geen resultaten gevonden op basis van de geselecteerde filters. Pas uw filters aan of herstel de selectie.",
        "Previous page": "Vorige",
        "Next page": "Volgende",
        "city": "Locatie",
        "service": "Dienst",
        "Activity": "Werkzaamheden",
        "Service": "Diensten",
        "Page": "",
        "Project": "Project",
        "Vacancy": "Vacature",
        "Form": "",
        "%% activities": "%% werkzaamheden",
        "All %% activities": "Alle %% werkzaamheden",
        "New": "Nieuw",
        "All activities": "Alle werkzaamheden",
        "Activities": "Werkzaamheden",
        "activities": "Werkzaamheden",
        "services": "Diensten",
        "pages": "Pagina's",
        "Request quote": "Offerte aanvragen",
        "Search": "Zoeken",
        "No results": "Geen resultaten",
        "test": "test werkt!nl"
    },
    "gr": {
        "job_hours": "Hou laang?",
        "location": "Woar den?",
        "education_level": "Hou klouk?",
        "New": "Nij",
        "All activities": "Alle waarkzoamheden",
        "Activities": "Waarkzoamheden",
        "activities": "Waarkzoamheden",
        "services": "Dainstn",
        "pages": "Poaginoas",
        "Request quote": "Offerte aanvraagn",
        "Search": "Zuiken",
        "No results": "Goin resultoat'n mienjong",
        "test": "test werkt!gr"
    },
    "en": {
        "test": "testwerkt!en"
    }
}

let locale = window.lang

export function translate (string, variables=false) {
    // console.log(string, variables, window.lang)
    if (!translations[locale].hasOwnProperty(string)) return console.log('Translation not found: ', string)
    return variables ? translations[locale][string].replace(/%%/g, (i => _ => variables[i++])(0)) : translations[locale][string]
}
