<template>
    <div class="search-fixture" :class="state ? 'active' : ''" @click.self.stop="toggleSearch">
        <div ref="searchWrapper" class="cols rw center search-wrapper">
            <div class="col sm-12 md-6 np search-container oh">
                <div class="search-input">
                    <input ref="searchInput" v-model="searchQuery" type="text" name="search" :placeholder="__('Search')" @keyup="whileTyping" autocomplete="off"/>
                    <div class="icon" @click="whileTyping">
                        <i class="bi bi-search"></i>
                    </div>
                </div>

                <ul ref="searchResults" class="search-results" v-show="results.length > 0">
                    <li v-for="item, index in results">
                        <a :href="item.url" class="rw nw middle space" :class="index === suggestIndex ? 'active' : ''">
                            <div class="icon hidden" v-show="index === suggestIndex">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.0027 6.5979L4.40965 0.00488281L3.00488 1.40965L9.5979 8.00267L3.00536 14.5952L4.41013 16L11.0027 9.40744L11.0049 9.40965L12.4097 8.00488L12.4074 8.00267L12.4101 7.99999L11.0054 6.59522L11.0027 6.5979Z" fill="currentColor"></path></svg>
                            </div>

                            <span class="title">{{ item['name'] }}</span>
                            <span class="type op-48" v-html="__(item['type'])"/>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup>
    import gsap from 'gsap'
    import axios from 'axios'
</script>

<script>
    export default {
        expose: ['toggleSearch'],
        props: ['screenWidth'],

        data() {
            return {
                state: false,
                loading: false,
                searchQuery: '',
                suggestions: [],
                results: [],
                fresh: true,
                suggestIndex: -1
            }
        },

        created () {
        },

        unmounted () {
        },

        mounted() {
        },

        methods: {
            toggleSearch() {
                this.state = !this.state

                if (this.state) this.$refs.searchInput.focus()

                gsap.to(this.$refs.searchWrapper, 0.24, {
                    ease: this.state ? 'expo.out' : 'expo.in',
                    y: this.state ? 160 : 320,
                    opacity: this.state ? 1 : 0,
                    onComplete: () => {
                        if (!this.state) gsap.set(this.$refs.searchWrapper, {
                            y: 0
                        })
                    }
                })
            },

            nav(dir=false) {
                if (dir && this.suggestIndex >= 1) this.suggestIndex -= 1
                if (!dir && this.suggestIndex < this.results.length - 1) this.suggestIndex += 1

                var topPos = this.$refs.searchResults.querySelector('.active').offsetTop

                    
                // gsap.to(this.$refs.searchResults, {
                //     ease: 'expo.out',
                //     duration: 0.4,
                //     scrollTo: this.$refs.searchResults.querySelector('.active'),
                //     onComplete: () => {
                //         console.log('asdasdasd')
                //     }
                // })
            },

            whileTyping(e) {
                // console.log(e.keyCode)
                if (e.keyCode == 13) return this.openPage()

                else if (e.keyCode == 27) return this.toggleSearch()

                else if ([38, 40].includes(e.keyCode)) {
                    if (this.fresh) this.fresh = false
                    return this.nav(e.keyCode == 38)
                }

                else if (e.key.length === 1) {
                    this.suggestIndex = -1

                    let formData = new FormData()

                    axios.defaults.xsrfCookieName = 'csrftoken'
                    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

                    formData.append("query", this.searchQuery)
                    formData.append("lang", window.lang)

                    axios.post('/api/v2/search/', formData)
                        .then( response => {

                            if (response.data != this.results) this.results = response.data

                            if (this.results.length && this.fresh) {
                                return this.nav(false)
                            }
                        }).catch(function (error) {})
                } else if (!this.searchQuery.length) {
                    this.results = []
                    this.fresh = true
                }
            },

            openPage() {
                if (this.results.length) {
                    window.location.href = this.results[this.suggestIndex]['url']
                }
            }
        },

        computed: {
            searchResults() {
                if (this.searchQuery) return 'moieme'
                else return false
            },

            mobile() {
                return this.screenWidth <= 704
            }
        }
    };
</script>