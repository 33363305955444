<template>
    <figure class="services-image" ref="images">
        <img class="bg op-0" src="../../img/foundation_1.png" alt="Service image">

        <template v-if="activeType == 'nieuwbouw'">
            <img class="anim foundation" src="../../img/foundation_1.png" alt="Service image">

            <img class="anim build" src="../../img/build_base_1.png" alt="Service image" style="z-index: 1;">
            <img class="anim build" src="../../img/build_base_2.png" alt="Service image" style="z-index: 0;">
            <img class="anim build" src="../../img/build_base_3.png" alt="Service image" style="z-index: 0;">
            <img class="anim build" src="../../img/build_base_4.png" alt="Service image" style="z-index: 2;">
            <img class="anim build" src="../../img/build_base_5.png" alt="Service image" style="z-index: 2;">
            <img class="anim build" src="../../img/build_base_6.png" alt="Service image" style="z-index: 2;">
            <img class="anim build" src="../../img/build_base_7.png" alt="Service image" style="z-index: 2;">

            <img class="anim build" src="../../img/build_middle_1.png" alt="Service image" style="z-index: 2;">
            <img class="anim build" src="../../img/build_middle_2.png" alt="Service image" style="z-index: 1;">
            <img class="anim build" src="../../img/build_middle_3.png" alt="Service image" style="z-index: 1;">
            <img class="anim build" src="../../img/build_middle_4.png" alt="Service image" style="z-index: 12;">
            <img class="anim build" src="../../img/build_middle_5.png" alt="Service image" style="z-index: 12;">
            <img class="anim build" src="../../img/build_middle_6.png" alt="Service image" style="z-index: 13;">
            <img class="anim build" src="../../img/build_middle_7.png" alt="Service image" style="z-index: 13;">

            <!-- <img class="anim build" src="../../img/build_roof_1.png" alt="Service image"> -->
            <img class="anim build roof" src="../../img/build_roof_2.png" alt="Service image" style="z-index: 11;">
            <img class="anim build roof" src="../../img/build_roof_3.png" alt="Service image" style="z-index: 10;">
            <img class="anim build roof" src="../../img/build_roof_4.png" alt="Service image" style="z-index: 9;">
            <img class="anim build roof" src="../../img/build_roof_5.png" alt="Service image" style="z-index: 8;">
            <img class="anim build roof" src="../../img/build_roof_6.png" alt="Service image" style="z-index: 7;">
            <img class="anim build roof" src="../../img/build_roof_7.png" alt="Service image" style="z-index: 6;">
            <img class="anim build roof" src="../../img/build_roof_8.png" alt="Service image" style="z-index: 5;">
            <img class="anim build roof" src="../../img/build_roof_9.png" alt="Service image" style="z-index: 4;">
            <img class="anim build " src="../../img/build_roof_10.png" alt="Service image" style="z-index: 0;">
            <img class="anim build roof" src="../../img/build_roof_11.png" alt="Service image" style="z-index: 22;">
            <img class="anim build" src="../../img/build_roof_12.png" alt="Service image" style="z-index: 0;">
            <img class="anim build" src="../../img/build_roof_13.png" alt="Service image" style="z-index: 22;">
            <img class="anim build" src="../../img/schoorsteen_1.png" alt="Service image" style="z-index: 22;">
        </template>

        <template v-if="activeType == 'renovatie'">
            <img class="anim base" src="../../img/build_base_1.png" alt="Service image" style="z-index: 1;">
            <img class="anim base" src="../../img/build_base_2.png" alt="Service image" style="z-index: 0;">
            <img class="anim base" src="../../img/build_base_3.png" alt="Service image" style="z-index: 0;">
            <img class="anim base" src="../../img/build_base_4.png" alt="Service image" style="z-index: 2;">
            <img class="anim base" src="../../img/build_base_5.png" alt="Service image" style="z-index: 2;">
            <img class="anim base align" src="../../img/build_base_6.png" alt="Service image" style="z-index: 2; transform: rotate(1deg)">
            <img class="anim base filth" src="../../img/renovatie_base_filth_1.png" alt="Service image" style="z-index: 2;">
            <img class="anim base plank" src="../../img/renovatie_base_plank_1.png" alt="Service image" style="z-index: 2; transform: rotate(1deg)">
            <img class="anim base door" src="../../img/base_door_small_1.png" alt="Service image" style="z-index: 2; filter: saturate(50%);">

            <img class="anim middle" src="../../img/build_middle_1.png" alt="Service image" style="z-index: 2;">
            <img class="anim middle" src="../../img/build_middle_2.png" alt="Service image" style="z-index: 1;">
            <img class="anim middle" src="../../img/build_middle_3.png" alt="Service image" style="z-index: 1;">
            <img class="anim middle" src="../../img/build_middle_4.png" alt="Service image" style="z-index: 12;">
            <img class="anim middle" src="../../img/build_middle_5.png" alt="Service image" style="z-index: 12;">
            <img class="anim middle align" src="../../img/build_middle_6.png" alt="Service image" style="z-index: 13; transform: rotate(-4deg)">
            <img class="anim middle align" src="../../img/build_middle_7.png" alt="Service image" style="z-index: 13; transform: rotate(2deg)">

            <img class="anim roof" src="../../img/build_roof_2.png" alt="Service image" style="z-index: 11;">
            <img class="anim roof" src="../../img/build_roof_3.png" alt="Service image" style="z-index: 10;">
            <img class="anim roof" src="../../img/build_roof_4.png" alt="Service image" style="z-index: 9;">
            <img class="anim roof" src="../../img/build_roof_5.png" alt="Service image" style="z-index: 8;">
            <img class="anim roof" src="../../img/build_roof_6.png" alt="Service image" style="z-index: 7;">
            <img class="anim roof" src="../../img/build_roof_7.png" alt="Service image" style="z-index: 6;">
            <img class="anim roof" src="../../img/build_roof_8.png" alt="Service image" style="z-index: 5;">
            <img class="anim roof" src="../../img/build_roof_9.png" alt="Service image" style="z-index: 4;">
            <img class="anim roof" src="../../img/build_roof_10.png" alt="Service image" style="z-index: 3;">
            <img class="anim roof" src="../../img/build_roof_11.png" alt="Service image" style="z-index: 22;">
            <img class="anim roof old" src="../../img/renovatie_roof_13.png" alt="Service image" style="z-index: 22; filter: saturate(50%);">
            <img class="anim roof old" src="../../img/build_roof_12.png" alt="Service image" style="z-index: 0; filter: saturate(50%);">

            <img class="anim new" src="../../img/build_roof_12.png" alt="Service image" style="z-index: 0;">
            <img class="anim new" src="../../img/build_roof_13.png" alt="Service image" style="z-index: 22;">
            <img class="anim new" src="../../img/dakkapel_1.png" alt="Service image" style="z-index: 22;">
            <img class="anim new" src="../../img/schoorsteen_1.png" alt="Service image" style="z-index: 22;">
        </template>

        <template v-if="activeType == 'verbouw'">
            <img class="anim base" src="../../img/base_1.png" alt="Service image">
            <img class="anim middle" src="../../img/middle_1.png" alt="Service image">
            <img class="anim roof" src="../../img/roof_1.png" alt="Service image">
            <img class="anim dakkapel" src="../../img/dakkapel_1.png" alt="Service image">
            <img class="anim schoorsteen" src="../../img/schoorsteen_1.png" alt="Service image">
        </template>

        <template v-if="activeType == 'aardbevingsschade'">
            <img class="anim base" src="../../img/base_1.png" alt="Service image">
            <img class="anim middle" src="../../img/middle_1.png" alt="Service image">
            <img class="anim roof" src="../../img/roof_1.png" alt="Service image">
            <img class="anim dakkapel" src="../../img/dakkapel_1.png" alt="Service image">
            <img class="anim schoorsteen" src="../../img/schoorsteen_1.png" alt="Service image">
            <img class="anim level" src="../../img/level_0.png" alt="Service image">
        </template>

        <template v-else-if="activeType == 'verduurzaming'">
            <img class="anim base" src="../../img/base_1.png" alt="Service image">
            <img class="anim middle" src="../../img/middle_1.png" alt="Service image">
            <img class="anim roof" src="../../img/roof_1.png" alt="Service image">
            <img class="anim schoorsteen" src="../../img/schoorsteen_1.png" alt="Service image">

            <img class="anim solar" src="../../img/solar_1.png" alt="Service image">
            <img class="anim solar" src="../../img/solar_2.png" alt="Service image">
            <img class="anim solar" src="../../img/solar_3.png" alt="Service image">
            <img class="anim solar" src="../../img/solar_4.png" alt="Service image">
            <img class="anim solar" src="../../img/solar_5.png" alt="Service image">

            <img class="anim solar" src="../../img/solar_6.png" alt="Service image">
            <img class="anim solar" src="../../img/solar_7.png" alt="Service image">
            <img class="anim solar" src="../../img/solar_8.png" alt="Service image">
            <img class="anim solar" src="../../img/solar_9.png" alt="Service image">
            <img class="anim solar" src="../../img/solar_10.png" alt="Service image">

            <img class="anim pump" src="../../img/warmtepomp_2.png" alt="Service image">
        </template>

        <div class="bus" ref="bus">
            <img ref="bus" src="../../img/bus.png" style="z-index: 22;">
        </div>

        <img class="anim tuut" src="../../img/tuut_1.png" style="z-index: 28;">
        <img class="anim tuut" src="../../img/tuut_2.png" style="z-index: 28;">

        <pois ref="poisCon" v-show="pois.length > 0" :content="pois" :fade="true"/>
    </figure>
</template>

<script setup>
    import {gsap, TweenMax} from 'gsap'
    import Pois from './Pois'
</script>

<script>
    export default {
        name: 'Stacked',
        expose: ['set'],
        components: [Pois],
        props: ['data', 'type', 'dynamic'],

        data() {
            return {
                speed: 0.64,
                images: [],
                pois: [],
                poisContent: [],
                activeType: ''
            }
        },

        mounted() {
            if (!this.dynamic) {
                this.pois = []
                if (this.type) this.activeType = this.type
                if (this.data) this.poisContent = this.data.pois
                this.reload()
            }
        },

        methods: {
            set(content) {
                this.pois = []
                this.poisContent = content['pois']
                this.activeType = content['type']
                this.reload()
            },

            reload() {
                let that = this,
                    timeline = gsap.timeline()

                setTimeout(function(){
                    timeline.to(that.$refs.images.querySelectorAll('img:not(.bg)'), {
                        duration: 0,
                        y: '0%',
                        x: '0%',
                        opacity: 1
                    })

                    timeline.to(that.$refs.images.querySelectorAll('.tuut'), {
                        duration: 0,
                        opacity: 0,
                        y: 0
                    })

                    timeline.to(that.$refs.images.querySelectorAll('img.door'), {
                        duration: 0,
                        css: {
                            'filter': 'saturate(50%)'
                        }
                    })

                    timeline.to(that.$refs.images.querySelectorAll('img.new'), {
                        duration: 0,
                        opacity: 0,
                        y: '-60%',
                    })

                    timeline.to(that.$refs.bus, {
                        duration: 0,
                        opacity: 0,
                        x: '-100rem',
                        y: '50rem',
                    })

                    if (that.activeType == 'verduurzaming') that.verduurzaming(timeline)
                    if (that.activeType == 'nieuwbouw') that.nieuwbouw(timeline)
                    if (that.activeType == 'verbouw') that.verbouw(timeline)
                    if (that.activeType == 'renovatie') that.renovatie(timeline)
                    if (that.activeType == 'aardbevingsschade') that.aardbevingsschade(timeline)
                }, 200)
            },

            randomIntFromInterval(min, max) {
                return Math.floor(Math.random() * (max - min + 1) + min)
            },

            renovatie(timeline) {
                timeline.to(this.$refs.bus, {
                    ease: 'back.out(0.5)',
                    opacity: 1,
                    x: '0',
                    y: '0',
                    duration: 1
                })

                timeline.to(this.$refs.bus, {
                    duration: 0.5
                })

                timeline.to(this.$refs.images.querySelectorAll('img.plank'), {
                    ease: 'back.in(.7)',
                    opacity: 0,
                    x: '-20rem',
                    y: '10rem',
                    duration: 0.2,
                    stagger: 0.2
                })

                timeline.to(this.$refs.images.querySelectorAll('.old.roof'), {
                    ease: 'back.in(.7)',
                    opacity: 0,
                    y: '-60%',
                    duration: 0.48,
                    stagger: 0.24
                })

                timeline.to(this.$refs.images.querySelectorAll('.align'), {
                    ease: 'sine',
                    rotate: 0,
                    duration: 0.48,
                    stagger: 0.24
                })

                timeline.to(this.$refs.images.querySelectorAll('img.door'), {
                    ease: 'expo',
                    duration: 0.4,
                    css: {
                        'filter': 'saturate(100%)'
                    }
                })

                timeline.to(this.$refs.images.querySelectorAll('.filth'), {
                    ease: 'linear',
                    duration: 0.8,
                    opacity: 0
                })

                timeline.to(this.$refs.images.querySelectorAll('img.new'), {
                    ease: 'back.out(.7)',
                    opacity: 1,
                    y: '0',
                    x: '0',
                    duration: 0.4,
                    stagger: 0.2
                })

                timeline.to(this.$refs.bus, {
                    ease: 'back.in(0.8)',
                    opacity: 1,
                    x: '100rem',
                    y: '-50rem',
                    duration: 2,
                    onStart: () => {
                        this.afterAnimation(timeline)
                    }
                })
            },

            aardbevingsschade(timeline) {
                timeline.to(this.$refs.images.querySelector('.level'), {
                    duration: 0,
                    opacity: 0,
                    x: '-100rem',
                    y: '50rem',
                })

                timeline.to(this.$refs.images.querySelectorAll('.base, .middle, .roof, .schoorsteen, .dakkapel'), {
                    duration: 0,
                    y: '-60%',
                    opacity: 0
                })

                timeline.to(this.$refs.images.querySelectorAll('.base, .middle, .roof, .schoorsteen, .dakkapel'), {
                    ease: 'back.out(.7)',
                    opacity: 1,
                    y: '0',
                    duration: 0
                })

                let shakes = 10

                for (var i = shakes - 1; i >= 0; i--) {
                    this.$refs.images.querySelectorAll('.base, .middle, .roof, .schoorsteen, .dakkapel').forEach( item => {
                        let diff = this.randomIntFromInterval(0, 5),
                            diffR = this.randomIntFromInterval(0.2, 0.8)

                        timeline.to(item, {
                            ease: 'expo.out',
                            y: Math.random() < 0.5 ? diff : -diff,
                            rotate: Math.random() < 0.5 ? diffR : -diffR,
                            duration: 0.02,
                        })
                    })
                }

                timeline.to(this.$refs.images.querySelectorAll('.base, .middle, .roof, .schoorsteen, .dakkapel'), {
                    ease: 'back.out(.7)',
                    opacity: 1,
                    y: 0,
                    x: 0,
                    rotate: 0,
                    duration: 0.4,
                    onStart: () => {
                        gsap.to(this.$refs.images.querySelectorAll('.level'), {
                            ease: 'back.out(.7)',
                            opacity: 1,
                            y: '0',
                            x: '0',
                            duration: 0.8
                        })
                    }
                })
            },

            nieuwbouw(timeline) {
                timeline.to(this.$refs.images.querySelectorAll('.base, .build, .middle, .dakkapel, .schoorsteen'), {
                    duration: 0,
                    y: '-60%',
                    opacity: 0
                })

                timeline.to(this.$refs.images.querySelectorAll('.roof'), {
                    duration: 0,
                    y: '5rem',
                    opacity: 0
                })

                timeline.to(this.$refs.images.querySelectorAll('.foundation'), {
                    duration: 0,
                    scale: 0.5
                })

                timeline.to(this.$refs.images.querySelectorAll('.foundation'), {
                    ease: 'expo',
                    scale: 1,
                    opacity: 1,
                    duration: 0.6,
                })

                timeline.to(this.$refs.bus, {
                    ease: 'back.out(0.5)',
                    opacity: 1,
                    x: '0',
                    y: '0',
                    duration: 1
                })

                timeline.to(this.$refs.images.querySelectorAll('.build'), {
                    ease: 'back.out(.7)',
                    opacity: 1,
                    y: '0',
                    duration: 0.4,
                    stagger: 0.1
                })

                timeline.to(this.$refs.bus, {
                    ease: 'back.in(0.8)',
                    opacity: 1,
                    x: '100rem',
                    y: '-50rem',
                    duration: 2,
                    onStart: () => {
                        this.afterAnimation(timeline)
                    }
                })
            },

            verduurzaming(timeline) {
                timeline.to(this.$refs.images.querySelectorAll('.base, .middle, .roof, .schoorsteen, .solar, .pump'), {
                    duration: 0,
                    y: '-60%',
                    opacity: 0
                })

                timeline.to(this.$refs.images.querySelectorAll('.base, .middle, .roof, .schoorsteen'), {
                    ease: 'back.out(.7)',
                    opacity: 1,
                    y: '0',
                    duration: 0
                })

                timeline.to(this.$refs.bus, {
                    duration: 0.4
                })

                timeline.to(this.$refs.bus, {
                    ease: 'back.out(0.5)',
                    opacity: 1,
                    x: '0',
                    y: '0',
                    duration: 1
                })

                timeline.to(this.$refs.bus, {
                    duration: 0.4
                })

                timeline.to(this.$refs.images.querySelectorAll('.solar'), {
                    ease: 'back.out(.7)',
                    opacity: 1,
                    y: '0',
                    duration: 0.4,
                    stagger: 0.1
                })

                timeline.to(this.$refs.images.querySelector('.pump'), {
                    ease: 'back.out(0.5)',
                    opacity: 1,
                    x: '0',
                    y: '0',
                    duration: 0.4
                })

                timeline.to(this.$refs.images.querySelector('.middle'), {
                    ease: 'back.out(.7)',
                    opacity: 1,
                    x: '0',
                    duration: 0.2
                })

                timeline.to(this.$refs.bus, {
                    ease: 'back.in(0.8)',
                    opacity: 1,
                    x: '100rem',
                    y: '-50rem',
                    duration: 2,
                    onStart: () => {
                        this.afterAnimation(timeline)
                    }
                })
            },

            verbouw(timeline) {
                timeline.to(this.$refs.images.querySelectorAll('.base, .roof, .dakkapel, .schoorsteen'), {
                    duration: 0,
                    y: '-60%',
                    opacity: 0
                })

                timeline.to(this.$refs.images.querySelectorAll('.middle'), {
                    duration: 0,
                    x: '100%',
                    opacity: 0
                })

                timeline.to(this.$refs.images.querySelector('.base'), {
                    ease: 'back.out(.7)',
                    opacity: 1,
                    y: '0',
                    duration: 0
                })

                timeline.to(this.$refs.images.querySelector('.roof'), {
                    ease: 'back.out(.7)',
                    opacity: 1,
                    y: '18%',
                    duration: 0
                })

                timeline.to(this.$refs.bus, {
                    duration: 1.5
                })

                timeline.to(this.$refs.bus, {
                    ease: 'back.out(0.5)',
                    opacity: 1,
                    x: '0',
                    y: '0',
                    duration: 1
                })

                timeline.to(this.$refs.images.querySelector('.roof'), {
                    ease: 'back.out(.7)',
                    opacity: 1,
                    y: '-20%',
                    duration: 1
                })

                timeline.to(this.$refs.images.querySelector('.middle'), {
                    ease: 'back.out(.7)',
                    opacity: 1,
                    x: '0',
                    duration: 0.4
                })

                timeline.to(this.$refs.images.querySelector('.roof'), {
                    ease: 'back.out(.7)',
                    opacity: 1,
                    y: '0',
                    duration: 0.2
                })

                timeline.to(this.$refs.images.querySelector('.dakkapel'), {
                    ease: 'back.out(.7)',
                    opacity: 1,
                    y: '0',
                    duration: 0.2
                })

                timeline.to(this.$refs.images.querySelector('.schoorsteen'), {
                    ease: 'back.out(.7)',
                    opacity: 1,
                    y: '0',
                    duration: 0.2
                })

                timeline.to(this.$refs.bus, {
                    ease: 'back.in(0.8)',
                    opacity: 1,
                    x: '100rem',
                    y: '-50rem',
                    duration: 2,
                    onStart: () => {
                        this.afterAnimation(timeline)
                    }
                })
            },

            afterAnimation(timelin) {
                this.pois = this.poisContent

                let timeline = gsap.timeline()

                timeline.to(this.$refs.images.querySelectorAll('.tuut'), {
                    opacity: 1,
                    y: '-5%',
                    delay: 0.6,
                    rotate: '1deg',
                    ease: 'ease.out',
                    duration: 0.4,
                    stagger: 0.2
                })

                timeline.to(this.$refs.images.querySelectorAll('.tuut'), {
                    opacity: 0,
                    x: '0rem',
                    rotate: '-5deg',
                    ease: 'ease.out',
                    duration: 0.4,
                    stagger: 0.1
                }, 1)

                if (this.$refs.poisCon) this.$refs.poisCon.reload()
            }
        }
    }

</script>