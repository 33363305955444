<template>
    <div class="cols rw reverse-sm-down space middle">
        <div class="col sm-12 md-5 np">
            <div class="services-top cl hp p-y-top">
                <h2 class="w500">{{ info.title }}</h2>
                <p class="content" v-html="info.intro"/>
                <a :href="button.url" class="button secondary" v-for="button in info.buttons">
                    <span>{{ button.label }}</span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.0027 6.5979L4.40965 0.00488281L3.00488 1.40965L9.5979 8.00267L3.00536 14.5952L4.41013 16L11.0027 9.40744L11.0049 9.40965L12.4097 8.00488L12.4074 8.00267L12.4101 7.99999L11.0054 6.59522L11.0027 6.5979Z" fill="currentColor"/></svg>
                </a>
            </div>

            <ul class="services-list">
                <li class="_flappie list-heading" v-for="service in services" :class="activeItem['id'] == service['id'] ? 'active' : ''" @click="setActive(service)">
                    <div class="inner">
                        <div class="rw nw middle">
                            <i class="toggle">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.0027 6.5979L4.40965 0.00488281L3.00488 1.40965L9.5979 8.00267L3.00536 14.5952L4.41013 16L11.0027 9.40744L11.0049 9.40965L12.4097 8.00488L12.4074 8.00267L12.4101 7.99999L11.0054 6.59522L11.0027 6.5979Z" fill="currentColor"/></svg>
                            </i>

                            <div class="content cl">
                                <div class="rw nw base">
                                    <h3>{{ service['name'] }}</h3>
                                    <div class="rw nw center middle _new w500" v-if="service.new">
                                        <span v-html="__('New')"/>
                                    </div>
                                </div>

                                <div class="cl more">
                                    <div v-if="service['description']" v-html="service['description']"/>
                                    <a :href="activeItem['url']" class="button text white">
                                        <span>{{ activeItem['cta_label'] }}</span>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.0027 6.5979L4.40965 0.00488281L3.00488 1.40965L9.5979 8.00267L3.00536 14.5952L4.41013 16L11.0027 9.40744L11.0049 9.40965L12.4097 8.00488L12.4074 8.00267L12.4101 7.99999L11.0054 6.59522L11.0027 6.5979Z" fill="currentColor"/></svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="col sm-8 md-6">
            <stacked ref="stack" :dynamic="true"/>
        </div>
    </div>
</template>

<script setup>
    import gsap from 'gsap'
    import Stacked from './Stacked'
</script>

<script>
    export default {
        name: 'Services',
        props: ['content'],
        components: [Stacked],

        data() {
            return {
                activeItem: false,
                timeline: false
            }
        },

        mounted() {
            this.setActive(this.services[0])
        },

        methods: {
            setActive(service) {
                if (this.activeItem['type'] == service['type']) return false

                this.activeItem = service
                
                let stackedContent = {
                    'type': this.activeItem['type'],
                    'pois': this.activeItem['pois']
                }

                this.$refs.stack.set(stackedContent)
            }
        },

        computed: {
            info() {
                return this.content.hasOwnProperty('info') ? this.content['info'] : false
            }, 

            services() {
                return this.content.hasOwnProperty('services') ? this.content['services'] : false
            },

            mobile() {
                return window.innerWidth <= 704
            }
        }
    };

</script>