<template>
    <div class="catalog-wrapper" ref="catalogWrapper" id="catalog-wrapper">
        <div class="cols">
            <div class="col sm-12 md-10 lg-7">
                <div class="catalog-heading">
                    <h2 v-html="heading" v-if="heading"/>
                    <p v-html="intro" v-if="intro"/>
                </div>
            </div>
        </div>

        <div class="cols">
            <div class="col sm-12 md-12" v-if="activeFilters.length">
                <b class="hide-sm" v-html="__('Active filters')"></b>
                <ul class="active-filters rw">
                    <li v-for="filter in activeFilters" @click="addFilter(filter)">
                        <span v-html="filter.label"/>
                        <i class="bi bi-x-circle-fill"/>
                    </li>
                </ul>
            </div>

            <div class="button primary filters hide-sm" :class="scrollStop ? 'scroll-stop' : ''" @click="toggleFilters">
                <span v-html="__((filtersActive ? 'Show ' : 'Filter ') + type)"/>
                <div class="count">{{ activeFilters.length }}</div>
            </div>

            <div class="col sm-12 md-4 lg-3 filters-fixture" :class="mobile && filtersActive ? 'active' : ''">
                <perfect-scrollbar :use-both-wheel-axes="false" class="filters-wrapper">
                    <div class="filters-header rw nw space middle hide-sm">
                        <span class="title" v-html="__('Filter ' + type)"/>

                        <div class="close tip-wrap" @click="toggleFilters">
                            <i class="bi bi-x-lg"></i>
                            <span class="tip" v-html="__('Close')"/>
                        </div>
                    </div>

                    <ul>
                        <li v-for="filter in filters" class="filter" :class="filter.id" v-show="filter.options.length > 0">
                            <h3 v-html="__(filter.id)"/>

                            <label class="option" v-for="option in filter.options" @click="addFilter(option)">
                                <div class="input-checkbox" :class="option.checked ? 'checked' : ''"></div>
                                <span>{{ option.label }}</span>
                            </label>
                        </li>
                    </ul>
                </perfect-scrollbar>
            </div>

            <div class="col sm-12 md-8 lg-9 cl np stretch">
                <div class="catalog rw" id="catalog" ref="catalog">
                    <article v-if="type == 'projects'" class="project-item" v-for="project in content.content">
                        <a :href="project.url" class="cl oh project-slide _flappie" :aria-label="project.name">
                            <div class="cl inner space stretch">
                                <div class="cl grow">
                                    <h2 class="">{{ project.name }}</h2>

                                    <address class="rw base">
                                        <span class="street">{{ project.street }}</span>
                                        <span class="city">{{ project.city }}</span>
                                    </address>
                                </div>

                                <ul class="rw nw tags fadeout">
                                    <li v-for="tag in project.tags">{{ tag }}</li>
                                </ul>

                                <svg class="icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 40C14.6957 40 9.60859 37.8929 5.85786 34.1421C2.10714 30.3914 -6.42368e-07 25.3043 -8.74228e-07 20C-1.10609e-06 14.6957 2.10714 9.60859 5.85786 5.85786C9.60859 2.10714 14.6957 -6.42368e-07 20 -8.74228e-07C25.3043 -1.10609e-06 30.3914 2.10713 34.1421 5.85786C37.8929 9.60859 40 14.6957 40 20C40 25.3043 37.8929 30.3914 34.1421 34.1421C30.3914 37.8929 25.3043 40 20 40ZM12.9925 25.24C12.8731 25.3553 12.7779 25.4932 12.7124 25.6457C12.6469 25.7982 12.6124 25.9623 12.6109 26.1282C12.6095 26.2942 12.6411 26.4588 12.704 26.6124C12.7668 26.7661 12.8596 26.9056 12.977 27.023C13.0944 27.1404 13.2339 27.2332 13.3876 27.296C13.5412 27.3589 13.7058 27.3905 13.8718 27.3891C14.0377 27.3876 14.2017 27.3531 14.3543 27.2876C14.5068 27.2221 14.6447 27.1269 14.76 27.0075L25 16.7675L25 23.6875C25 24.019 25.1317 24.337 25.3661 24.5714C25.6005 24.8058 25.9185 24.9375 26.25 24.9375C26.5815 24.9375 26.8995 24.8058 27.1339 24.5714C27.3683 24.337 27.5 24.019 27.5 23.6875L27.5 13.75C27.5 13.4185 27.3683 13.1005 27.1339 12.8661C26.8995 12.6317 26.5815 12.5 26.25 12.5L16.3125 12.5C15.981 12.5 15.663 12.6317 15.4286 12.8661C15.1942 13.1005 15.0625 13.4185 15.0625 13.75C15.0625 14.0815 15.1942 14.3995 15.4286 14.6339C15.663 14.8683 15.981 15 16.3125 15L23.2325 15L12.9925 25.24Z" fill="white"/>
                                </svg>
                            </div>

                            <figure v-if="project.image">
                                <img :src="project.image" :alt="project.name">
                            </figure>
                        </a>
                    </article>

                    <article v-if="type == 'vacancies'" class="vacancy-item" v-for="vacancy in content.content">
                        <a :href="vacancy.url" class="cl oh vacancy _flappie" :aria-label="vacancy.name">
                            <div class="cl inner space stretch">
                                <div class="cl grow">
                                    <h2 v-html="vacancy.name"/>
                                    <div class="description _mid" v-html="vacancy.short_description"/>

                                    <ul class="rw middle vacancy-details">
                                        <li class="rw nw middle" v-if="vacancy.education_level">
                                            <i class="bi bi-backpack"/>

                                            <div class="cl">
                                                <span class="key" v-html="__('Education')"/>
                                                <span class="value">{{ vacancy.education_level }}</span>
                                            </div>
                                        </li>

                                        <li class="rw nw middle" v-if="vacancy.job_hours">
                                            <i class="bi bi-clock-history"/>

                                            <div class="cl">
                                                <span class="key" v-html="__('Hours')"/>
                                                <span class="value">{{ vacancy.job_hours }}</span>
                                            </div>
                                        </li>

                                        <li class="rw nw middle" v-if="vacancy.location">
                                            <i class="bi bi-pin-map"/>

                                            <div class="cl">
                                                <span class="key" v-html="__('Location')"/>
                                                <span class="value">{{ vacancy.location }}</span>
                                            </div>
                                        </li>

                                        <li class="rw nw middle" v-if="vacancy.date_start">
                                            <i class="bi bi-calendar2-event"/>

                                            <div class="cl">
                                                <span class="key" v-html="__('Startdate')"/>
                                                <span class="value" v-html="startDate(vacancy.date_start)"/>
                                            </div>
                                        </li>

                                        <li class="rw nw middle" v-if="vacancy.personal_van">
                                            <i class="bi bi-truck"/>

                                            <div class="cl">
                                                <span class="value" v-html="__('Your own van')"/>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <figure v-if="vacancy.image">
                                    <img :src="vacancy.image" :alt="vacancy.name">
                                </figure>

                                <!-- <svg class="icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 40C14.6957 40 9.60859 37.8929 5.85786 34.1421C2.10714 30.3914 -6.42368e-07 25.3043 -8.74228e-07 20C-1.10609e-06 14.6957 2.10714 9.60859 5.85786 5.85786C9.60859 2.10714 14.6957 -6.42368e-07 20 -8.74228e-07C25.3043 -1.10609e-06 30.3914 2.10713 34.1421 5.85786C37.8929 9.60859 40 14.6957 40 20C40 25.3043 37.8929 30.3914 34.1421 34.1421C30.3914 37.8929 25.3043 40 20 40ZM12.9925 25.24C12.8731 25.3553 12.7779 25.4932 12.7124 25.6457C12.6469 25.7982 12.6124 25.9623 12.6109 26.1282C12.6095 26.2942 12.6411 26.4588 12.704 26.6124C12.7668 26.7661 12.8596 26.9056 12.977 27.023C13.0944 27.1404 13.2339 27.2332 13.3876 27.296C13.5412 27.3589 13.7058 27.3905 13.8718 27.3891C14.0377 27.3876 14.2017 27.3531 14.3543 27.2876C14.5068 27.2221 14.6447 27.1269 14.76 27.0075L25 16.7675L25 23.6875C25 24.019 25.1317 24.337 25.3661 24.5714C25.6005 24.8058 25.9185 24.9375 26.25 24.9375C26.5815 24.9375 26.8995 24.8058 27.1339 24.5714C27.3683 24.337 27.5 24.019 27.5 23.6875L27.5 13.75C27.5 13.4185 27.3683 13.1005 27.1339 12.8661C26.8995 12.6317 26.5815 12.5 26.25 12.5L16.3125 12.5C15.981 12.5 15.663 12.6317 15.4286 12.8661C15.1942 13.1005 15.0625 13.4185 15.0625 13.75C15.0625 14.0815 15.1942 14.3995 15.4286 14.6339C15.663 14.8683 15.981 15 16.3125 15L23.2325 15L12.9925 25.24Z" fill="white"/>
                                </svg> -->
                            </div>
                        </a>
                    </article>

                    <div class="inner cl center" v-if="content.content && content.content.length < 1">
                        <div class="no-results">
                            <i class="icon bi bi-house-x" v-if="type == 'project'"></i>
                            <i class="icon bi bi-person-workspace" v-else></i>
                            <h3 v-html="__('No results found')"/>
                            <p v-html="__('Please check your filters')"/>
                        </div>
                    </div>
                </div>

                <div class="pagination-wrapper rw nw space middle button-wrap" v-if="content.pagination && content.pagination.num_pages > 1">
                    <div class="button primary" :class="page == 1 ? 'disabled': ''" @click="paginate(false)">
                        <span v-html="__('Previous page')"/>
                    </div>

                    <ul class="pagination-numeric rw nw">
                        <li v-for="index in content.pagination.num_pages" :key="index" :class="page == index ? 'current' : ''" @click="gotoPage(index)">
                            <span>{{ index }}</span>
                        </li>
                    </ul>

                    <div class="button primary" :class="page == content.pagination.num_pages ? 'disabled': ''" @click="paginate(true)">
                        <span v-html="__('Next page')"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import gsap from 'gsap'
    import axios from 'axios'
    import _ from 'lodash'
    // import moment from 'moment'
    import ScrollToPlugin from "gsap/ScrollToPlugin"
    import ScrollTrigger from "gsap/ScrollTrigger"

    gsap.registerPlugin(ScrollToPlugin, ScrollTrigger)
</script>

<script>
    export default {
        props: ['heading', 'intro', 'type'],

        data() {
            return {
                loading: true,
                filters: [],
                content: [],
                page: 1,
                perPage: 16,
                init: true,
                filtersActive: false,
                scrollTop: 0,
                scrollStop: false
            }
        },

        created () {
            window.addEventListener('scroll', this.handleScroll);

        },

        unmounted () {
            window.removeEventListener('scroll', this.handleScroll);
        },

        mounted() {
            if (this.type == 'vacancies') this.perPage = 8
            this.getFilters()
        },

        methods: {
            handleScroll (event) {
                this.scrollTop = (window.pageYOffset || document.documentElement.scrollTop)

                if (!this.scrollStop && this.scrollTop > this.$refs.catalogWrapper.offsetHeight - window.innerHeight || this.scrollStop && this.scrollTop < this.$refs.catalogWrapper.offsetHeight - window.innerHeight) {
                    this.scrollStop = !this.scrollStop
                }
            },

            startDate(date) {
                return date
                // moment(date).format('MMMM')
            },

            getFilters() {
                let formData = new FormData()

                axios.defaults.xsrfCookieName = 'csrftoken'
                axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

                formData.append("type", this.type)
                formData.append("lang", window.lang)

                axios.post('/api/v2/filters/', formData)
                    .then( response => {
                        this.filters = response.data
                        this.getContent()
                    }).catch(function (error) {})
            },

            getContent(fade) {
                let formData = new FormData()

                axios.defaults.xsrfCookieName = 'csrftoken'
                axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

                formData.append("filters", this.checkedOptions)
                formData.append("page", this.page)
                formData.append("perPage", this.perPage)
                formData.append("lang", window.lang)

                axios.post('/api/v2/' + this.type + '/', formData)
                    .then( response => {
                        this.content = response.data

                        if (this.content.pagination.num_pages < this.page) this.page = 1

                        if (fade) this.fadeItems()
                        if (this.init) this.init = false
                        else {
                            let scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
                            if (scrollTop > this.$refs.catalogWrapper.offsetTop + window.innerHeight / 2) {
                                gsap.to(window, {
                                    ease: 'expo.out',
                                    duration: 0.4,
                                    scrollTo: '#catalog-wrapper'
                                })
                            }
                        }
                    }).catch(function (error) {})
            },

            fadeItems() {
                gsap.set(this.$refs.catalog.querySelectorAll('article'), {
                    y:-100,
                    opacity: 0
                })

                gsap.to(this.$refs.catalog.querySelectorAll('article'), {
                    delay: 0.08,
                    ease: 'expo.out',
                    duration: 0.32,
                    y: 0,
                    opacity: 1,
                    stagger: -0.04
                })
            },

            toggleFilters() {
                this.filtersActive = !this.filtersActive
            },

            addFilter(option) {
                option.checked = !option.checked

                gsap.to(this.$refs.catalog.querySelectorAll('article'), {
                    ease: 'expo.in',
                    duration: 0.32,
                    y: 100,
                    opacity: 0,
                    stagger: -0.04,
                    onComplete: () => {
                        this.getContent(true)
                    }
                })

            },

            gotoPage(index) {
                this.page = index
                this.getContent()
            },

            paginate(dir=false) { // next = true, prev = false
                let current = this.page
                this.page = Math.max(Math.min(dir ? this.page + 1 : this.page - 1, this.content.pagination.num_pages), 1) 
                if (this.page != current) this.getContent()
            }
        },

        computed: {
            checkedOptions() {
                let items = {}

                this.filters.forEach(filter => {
                    filter.options.forEach(option => {
                        if (option.checked) {
                            if (items.hasOwnProperty(filter.id)) items[filter.id].push(option.id)
                            else items[filter.id] = [option.id]
                        }
                    })
                })

                return JSON.stringify(items)
            },

            activeFilters() {
                let items = []

                this.filters.forEach(filter => {
                    filter.options.forEach(option => {
                        if (option.checked) items.push(option)
                    })
                })

                return items
            },

            mobile() {
                return window.innerWidth <= 704
            }
        }
    }
</script>